import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Transition for the dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MonthlyImageViewer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Sample data - replace with your actual data source
  const [images, setImages] = useState([
    {
      id: 1,
      date: "September-2024",
      title: "September-2024",
      imageUrl:
        "imgs/monthly-newsletter/8-mcverse-september-2024-update-stats.jpg",
    },
    {
      id: 2,
      date: "October-2024",
      title: "October 2024",
      imageUrl:
        "imgs/monthly-newsletter/6-survival-minecraft-mcverse-2024-10-stats.jpg",
      //description: "Serene winter landscape with snow-covered trees",
    },
    {
      id: 3,
      date: "November-2024",
      title: "November 2024",
      imageUrl:
        "imgs/monthly-newsletter/4-survival-minecraft-mcverse-2024-11-stats.jpg",
      //description: "Serene winter landscape with snow-covered trees",
    },
    {
      id: 4,
      date: "December-2024",
      title: "December 2024",
      imageUrl: "imgs/monthly-newsletter/3-mcverse-december-2024-12-stats.jpg",
      //description: "Serene winter landscape with snow-covered trees",
    },
    {
      id: 5,
      date: "January-2025",
      title: "January 2025",
      imageUrl: "imgs/monthly-newsletter/2-mcverse-january-2025-01-stats.jpg",
      //description: "Serene winter landscape with snow-covered trees",
    },
    {
      id: 6,
      date: "February-2025",
      title: "February 2025",
      imageUrl: "imgs/monthly-newsletter/2-mcverse-february-2025-02-stats.jpg",
      //description: "Serene winter landscape with snow-covered trees",
    },
    {
      id: 7,
      date: "March-2025",
      title: "March 2025",
      imageUrl:
        "imgs/monthly-newsletter/1-survival-minecraft-mcverse-2025-02-stats.jpg",
    },
  ]);

  // State for the dialog
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Group images by year
  const groupedByYear = images.reduce((acc, image) => {
    const year = image.date.split("-")[1];
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(image);
    return acc;
  }, {});

  // Sort months within each year in descending order (latest first)
  Object.keys(groupedByYear).forEach((year) => {
    groupedByYear[year].sort((a, b) => {
      // Map month names to their numerical values
      const monthMap = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
      };

      const monthA = a.date.split("-")[0];
      const monthB = b.date.split("-")[0];

      // Compare months numerically in descending order
      return monthMap[monthB] - monthMap[monthA];
    });
  });

  // Create a flat array of sorted images for dialog navigation
  const sortedImages = Object.keys(groupedByYear)
    .sort((a, b) => b - a) // Sort years descending
    .flatMap((year) => groupedByYear[year]);

  // Navigate to the previous image
  const handlePrevious = () => {
    const newIndex =
      (currentIndex - 1 + sortedImages.length) % sortedImages.length;
    setCurrentIndex(newIndex);
    setSelectedImage(sortedImages[newIndex]);
  };

  // Navigate to the next image
  const handleNext = () => {
    const newIndex = (currentIndex + 1) % sortedImages.length;
    setCurrentIndex(newIndex);
    setSelectedImage(sortedImages[newIndex]);
  };

  // Handle opening the dialog
  const handleOpenDialog = (image, index) => {
    setSelectedImage(image);
    // Find the correct index in the sortedImages array
    const sortedIndex = sortedImages.findIndex(
      (img) => img.date === image.date && img.imageUrl === image.imageUrl
    );
    setCurrentIndex(sortedIndex);
    setOpen(true);
  };

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 0 }}>
      <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
        Monthly Server Stats
      </Typography>

      {/* Display years and months */}
      {Object.keys(groupedByYear)
        .sort((a, b) => b - a)
        .map((year) => (
          <Box key={year} sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {year}
            </Typography>
            <Grid container spacing={2}>
              {groupedByYear[year].map((image, index) => {
                const globalIndex = images.findIndex(
                  (img) => img.id === image.id
                );
                return (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={image.id}>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        cursor: "pointer",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0px 10px 30px -5px rgba(0,0,0,0.3)",
                        },
                      }}
                      onClick={() => handleOpenDialog(image, globalIndex)}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        {image.date.split("-")[0]}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 0,
                          paddingBottom: "75%", // 4:3 aspect ratio
                          position: "relative",
                          overflow: "hidden",
                          borderRadius: 1,
                          mb: 1,
                        }}
                      >
                        <img
                          src={image.imageUrl}
                          alt={image.title}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Chip
                        label={image.date}
                        size="small"
                        color="primary"
                        sx={{ mt: 1 }}
                      />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ))}

      {/* Image Detail Dialog */}
      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <AppBar position="relative" color="primary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {selectedImage?.date} - {selectedImage?.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ p: 0, position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "black",
              position: "relative",
              minHeight: isMobile ? "50vh" : "70vh",
            }}
          >
            <img
              src={selectedImage?.imageUrl}
              alt={selectedImage?.title}
              style={{
                maxWidth: "100%",
                maxHeight: isMobile ? "50vh" : "70vh",
                objectFit: "contain",
              }}
            />

            {/* Navigation buttons */}
            <IconButton
              sx={{
                position: "absolute",
                left: 16,
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
              }}
              onClick={handlePrevious}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <IconButton
              sx={{
                position: "absolute",
                right: 16,
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
              }}
              onClick={handleNext}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>

          <Box sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              {selectedImage?.title}
            </Typography>
            <Typography variant="body1">
              {selectedImage?.description}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyImageViewer;
