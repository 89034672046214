import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import Players from "./Pages/Players";
import VillagerMarkets from "./Pages/VillagerMarkets";
import Markets from "./Pages/Markets";
import Rules from "./Pages/Rules";
import Guide from "./Pages/Guide";
import Contact from "./Pages/Contact";
import Map from "./Pages/Map";
import ResponsiveMenuBar from "./Components/ResponsiveMenuBar";
import Footer from "./Components/Footer";

const theme = createTheme({
  palette: {
    header: {
      main: "#215c9c", // Override success color
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ResponsiveMenuBar />
        <Routes>
          <Route path="/" element={<Home theme={theme} />} />
          <Route path="/map" element={<Map />} />
          <Route path="/players" element={<Players />} />
          <Route path="/villager-markets" element={<VillagerMarkets />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
export default App;
