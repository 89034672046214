import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import ButtonIpCopySnackbar from "../Components/ButtonIpCopySnackbar";
import MonthlyImageViewer from "../Components/MonthlyImagesViewer";
import PlayerBuilds from "../Components/PlayerBuilds";
import ScrollTop from "../Components/ScrollTop";

const survivalChips = [
  "Hard Survival",
  "1.21.4",
  "Permanent Claims",
  "Player Warps",
  "Searchable Player Market",
  "Fishing Competitions",
  "Death Chest",
  "PvP Toggle",
  "Dragon Slaying",
  "Phantom Toggle",
  "Simple Clans",
  "Big Doors",
  "Mini Blocks",
  "Image Frames",
  "Full Mob Spawns",
  "Proximity Chat",
  "Minepacks",
  "Spawners",
  "Player and Server Stats",
];
const extractionChips = [];

export default function Home(theme) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            // bgcolor: "background.paper",
            backgroundImage: "url(imgs/player-builds/2024-01-17_19.58.54.jpg)",
            backgroundSize: "cover",
            pt: 14,
            pb: 6,
            paddingBottom: "80px",
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              bgcolor: "#000",
              opacity: "80%",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="white"
              gutterBottom
            >
              Survival Minecraft
              <br />
              Long-Term Community
            </Typography>
            <Typography variant="h5" align="center" color="white" paragraph>
              {/* Builder-Friendly
                <br />
                Player obtained blocks and markets.
                <br />
                Buy and Sell Player builds (Experimental) */}
              A laid back minecraft community for mature players.
            </Typography>
            <Typography variant="h6" align="center" color="white" paragraph>
              Guaranteed Hosting until 2027.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <ButtonIpCopySnackbar />
            </Stack>
          </Container>
        </Box>

        <Container sx={{ py: 8 }} maxWidth="lg">
          <Typography
            component="h3"
            variant="h3"
            color="text.primary"
            gutterBottom
          >
            Long-Term Survival SMP
          </Typography>

          <Grid container spacing={4}>
            {/* 26k Days Survival */}
            <Grid item key="surival-info" xs={12} sm={8} md={8}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                variant="outlined"
              >
                {/* <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                  }}
                  image={process.env.PUBLIC_URL + '/logo512.png'}
                /> */}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    26k Days Survival - Permanent World
                  </Typography>
                  <Typography>
                    <List>
                      <ListItem>
                        Long term classic survival in a large open world. Play
                        at your own pace and terms.
                      </ListItem>
                      <ListItem>
                        All player mined blocks. Search the player markets to
                        boost your resources and save time.
                      </ListItem>
                      <ListItem>
                        Buy and Sell Builds. (Experimental WIP)
                      </ListItem>
                      <ListItem>
                        Free Rank upgrades as you play. Purchase Citizen with
                        $15,000 game cash.
                      </ListItem>
                    </List>
                  </Typography>

                  <Container sx={{ marginTop: "20px", paddingLeft: "5px" }}>
                    {survivalChips.map((chip) => (
                      <Chip
                        label={chip}
                        variant="outlined"
                        sx={{ margin: "3px" }}
                      />
                    ))}
                  </Container>
                </CardContent>
              </Card>
            </Grid>

            <Grid item key="surival-features" xs={12} sm={4} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                variant="outlined"
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Features
                  </Typography>
                  <List>
                    <ListItem>Large limitless world</ListItem>
                    <ListItem>Free 250k+ Claim Blocks</ListItem>
                    <ListItem>20 TPS most of the time</ListItem>
                    <ListItem>Hope to be around long time</ListItem>
                    <ListItem>Existing AceCraft &lt;3 Community</ListItem>
                    <ListItem>Builder Friendly, No Grief!</ListItem>
                    <ListItem>Fisher Friendly</ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Typography
            component="h5"
            variant="h5"
            color="text.primary"
            gutterBottom
            paddingTop={5}
            paddingBottom={2}
          >
            Additional Servers for experimenting and fun!
          </Typography>

          <Grid container spacing={4}>
            <Grid item key="vanilla-info" xs={12} sm={6} md={6}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                variant="outlined"
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Vanilla Survival
                  </Typography>
                  <Typography>
                    <List>
                      <ListItem>
                        A pure vanilla survival with Protecetion Stones. <br />
                        <br />
                        World resets will follow Minecraft Releases, so you can
                        test the newest version ASAP!
                      </ListItem>
                    </List>
                  </Typography>
                  <br />
                  <Typography gutterBottom variant="h6" component="h6">
                    Features
                  </Typography>
                  <List>
                    <ListItem>Try the latest minecraft version!</ListItem>
                    <ListItem>1000 block Spawn Radius</ListItem>
                    <ListItem>~50% Sleep</ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key="plots-info" xs={12} sm={6} md={6}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                variant="outlined"
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Creative Plots - Design and Testing
                  </Typography>
                  <Typography>
                    <List>
                      <ListItem>
                        A plots world for you to test your ideas.
                      </ListItem>
                      <ListItem>
                        Work in progress. If you have a use case you'd like to
                        try, ask!
                      </ListItem>
                    </List>
                  </Typography>
                  <br />
                  <Typography gutterBottom variant="h6" component="h6">
                    Features
                  </Typography>
                  <List>
                    <ListItem>Litematica Pasting (By Request)</ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Container sx={{ py: 2 }} maxWidth="lg">
          <MonthlyImageViewer></MonthlyImageViewer>
        </Container>

        <Container sx={{ py: 2 }} maxWidth="lg">
          <PlayerBuilds></PlayerBuilds>
        </Container>

        {/* Scroll to Top Button */}
        <ScrollTop />
      </main>
    </ThemeProvider>
  );
}
