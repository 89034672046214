import { Box, Container, Typography, Paper } from "@mui/material";

export default function VillagerShopData() {
  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="left">
          Villager Markets
        </Typography>
        <Typography
          variant="subtitle1"
          component="subtitle1"
          gutterBottom
          align="left"
        >
          Shop data from 2025-03-05
        </Typography>
        <Box
          component="iframe"
          src="https://mcverse.city/mcdealer/"
          sx={{
            width: "100%",
            height: "70vh",
            border: "none",
            borderRadius: "4px",
            mt: 2,
            mb: 2,
            boxShadow: 2,
          }}
          title="Villager Markets"
          allowFullScreen
        />
      </Paper>
    </Container>
  );
}
